<template>
  <div id="app" class="pb-3">
    <b-form @submit.prevent="doSubmit">
      <b-form-group label="kode_cabang" class="mb-3 f-13 bold" hidden>
        <b-input class="f-13" v-model="form.data.branch_code" />
      </b-form-group>
      <b-form-group label="fullname" class="mb-3 f-13 bold" hidden>
        <b-input class="f-13" v-model="form.data.fullname" />
      </b-form-group>
      <b-form-group label="latitude" class="mb-3 f-13 bold" hidden>
        <b-input class="f-13" v-model="form.data.latitude" />
      </b-form-group>
      <b-form-group label="longitude" class="mb-3 f-13 bold" hidden>
        <b-input class="f-13" v-model="form.data.longitude" />
      </b-form-group>
      <div class="p-3 text-center">
        <div>
          <h6 class="text-center" style="font-size: 13px">
            Koordinat Lokasi Kantor Cabang <br />
          </h6>
          <h6 style="text-transform: uppercase; font-size: 13px">
            {{ form.data.branch_name }}
          </h6>
          <span style="font-size: 12px">
            Latitude: {{ form.data.latitude }} | Longitude:
            {{ form.data.longitude }}
          </span>
        </div>
        <div class="map-example mt-3">
          <bing-map
            credentials="As6nrYCa3lv21vs78OyFrPSAeQ0l7J__Z8o9PHTFGcCKIQkXodw0LZdOl4znFu4I"
            :options="mapOptions"
            :center="{
              latitude: form.data.latitude,
              longitude: form.data.longitude,
            }"
          >
            <bing-map-layer>
              <bing-map-pushpin
                :location="{
                  latitude: form.data.latitude,
                  longitude: form.data.longitude,
                }"
                :options="{
                  icon: 'https://bingmapsisdk.blob.core.windows.net/isdksamples/defaultPushpin.png',
                }"
              >
              </bing-map-pushpin>
            </bing-map-layer>
          </bing-map>
        </div>
      </div>
      <b-col cols="12" class="justify-content-center d-flex" style="color: red">
        <span style="font-size: 10px">
          <i
            >*Silahkan registrasi lokasi kantor cabang, pastikan titik lokasi di
            <b>map</b> dan posisi anda sudah <b>sesuai</b>. <br />
            Jika ada error silahkan hubungi team IT Support.</i
          >
        </span>
      </b-col>
      <b-col cols="12" class="justify-content-center d-flex pb-5">
        <b-col cols="8" class="p-3">
          <b-button
            block
            class="w-100 button-sag"
            type="submit"
            :disabled="form.loading"
            >{{
              form.loading ? "Memproses..." : "Regis Lokasi Kantor"
            }}</b-button
          >
        </b-col>
      </b-col>
    </b-form>
  </div>
</template>
<style scoped>
.map-example {
  margin-top: 10px;
  width: 100%;
  height: 400px;
}
.button-sag {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #159895;
  border: none;
}
.button-sag:hover {
  background-color: #159895;
}
</style>
<script>
import { mapGetters } from "vuex";
import axios from "@/axios";
export default {
  props: {
    msg: String,
  },
  layerVisible: true,
  data() {
    return {
      form: {
        data: {
          branch_name: null,
          branch_code: null,
          nik: null,
          fullname: null,
          latitude: 0,
          longitude: 0,
        },
      },
      mapOptions: {
        zoom: 18,
        showLocateMeButton: true,
        supportedMapTypes: ["r", "a", "be2", "x", "os", "hc"],
        showTrafficButton: true,
      },
    };
  },
  created() {
    if (navigator.geolocation) {
      // Mendapatkan lokasi pengguna
      console.log(navigator.geolocation);
      navigator.geolocation.watchPosition(
        (position) => {
          // Mendapatkan koordinat latitude dan longitude
          this.form.data.latitude = position.coords.latitude;
          this.form.data.longitude = position.coords.longitude;
        },
        () => {
          // Menampilkan notifikasi jika gagal mendapatkan lokasi
          this.notif(
            "Maaf, Silahkan Aktifkan GPS Terlebih dahulu",
            "Pemberitahuan",
            "danger"
          );
        }
      );
    } else {
      // Menampilkan notifikasi jika browser tidak mendukung geolocation
      this.notif(
        "Maaf, Browser Anda Tidak Mendukung Geolocation",
        "Pemberitahuan",
        "danger"
      );
    }
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    setUpForm() {
      let userData = this.user.data;
      this.form.data.nik = userData.nik;
      this.form.data.fullname = userData.fullname;
      this.form.data.branch_code = userData.branch_code;
      this.form.data.branch_name = userData.branch_name;
    },
    async doSubmit() {
      this.form.loading = true;
      let url = "/presence/regis_lokasi";
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let payload = new FormData();
      for (let key in this.form.data) {
        payload.append(key, this.form.data[key]);
      }
      let req = await axios.post(url, payload, header);
      if (req.data.status) {
        this.form.loading = false;
        this.notif(
          "Terima Kasih Lokasi Kantor Berhasil Di Registrasi",
          "Sukses",
          "success"
        );
        setTimeout(() => {
          this.$router.push("/home");
        }, 2000);
      } else {
        this.form.loading = false;
        this.logout();
        this.notif(
          "Regis Lokasi Kantor Gagal (Kesalahan pada server)",
          "Gagal",
          "error"
        );
      }
    },
    notif(msg, title, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-full",
      });
    },
  },
  mounted() {
    this.setUpForm();
  },
};
</script>