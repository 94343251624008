<template>
  <div>
    <div class="baik-title w-100 bg-titles">
      <b-row class="g-0 d-flex align-items-center" no-gutters>
        <div class="bt-left d-flex p-3">
          <button
            type="button"
            @click="hasHistory() ? $router.go(-1) : $router.push('/')"
            class="m-0 p-0 btn btn-outline-success"
          >
            <b-icon icon="arrow-left-circle-fill" />
          </button>
        </div>
        <div>
          <h6 class="m-0 p-0 text-light f-14">Absensi Security</h6>
        </div>
      </b-row>
    </div>
    <div class="infog">
      <div class="background pb-5">
        <div class="p-3">
          <div class="mt-3 pb-2">
            <h1 class="waktu">{{ time }}</h1>
            <p class="tanggal">{{ date }}</p>
          </div>
        </div>
        <div class="p-3" style="margin: -30px 0 -30px 0">
          <div class="cek">
            <div class="detail">
              <div class="icon">
                <b-icon icon="arrow-up-circle-fill" class="icons1" />
              </div>
              <div class="desc">
                <div v-if="last.tap_masuk" class="jam">
                  <div class="jam">{{ last.tap_masuk }}</div>
                  <div class="tanggal">{{ formattedDate(last.tgl_masuk) }}</div>
                </div>
                <div v-else class="jam">-- : --</div>
              </div>
            </div>
            <div class="detail">
              <div class="icon">
                <b-icon icon="arrow-down-circle-fill" class="icons2" />
              </div>
              <div class="desc">
                <div v-if="last.tap_keluar" class="jam">
                  <div class="jam">{{ last.tap_keluar }}</div>
                  <div class="tanggal">
                    {{ formattedDate(last.tgl_keluar) }}
                  </div>
                </div>
                <div v-else class="jam">-- : --</div>
              </div>
            </div>
          </div>
          <p
            v-if="last.shift"
            class="pt-1"
            style="margin-bottom: -10px; font-size: 10px"
          >
            <i
              >* Informasi Data Absen Terakhir : Anda Telah Melakukan Absen Di
              Shift {{ last.shift }}
            </i>
          </p>
          <p v-else class="pt-1" style="margin-bottom: -10px; font-size: 10px">
            <i>* Informasi Data Absen Terakhir </i>
          </p>
        </div>
      </div>
    </div>
    <div class="b-cuti pb-5">
      <b-row no-gutters class="m-0">
        <b-col sm="12" class="p-3">
          <router-link to="/form-absen-security">
            <b-button block class="w-100 f-11 bold button-sag">Absen</b-button>
          </router-link>
        </b-col>
      </b-row>
      <b-row no-gutters class="m-0">
        <b-col xs="6" class="p-3">
          <b-form-group
            label="Pilih Bulan"
            class="f-13"
            style="font-weight: bold"
          >
            <b-form-select
              :options="opt.bulan"
              class="w-100 f-13"
              v-model="form.data.bulan"
              @change="getHistoriAbsensi()"
            />
          </b-form-group>
        </b-col>
        <b-col xs="6" class="p-3">
          <b-form-group
            label="Pilih Tahun"
            class="f-13"
            style="font-weight: bold"
          >
            <b-form-select
              :options="opt.tahun"
              class="w-100 f-13"
              v-model="form.data.tahun"
              @change="getHistoriAbsensi()"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row no-gutters class="m-0">
        <b-col sm="12" class="p-3">
          <div class="bd-card rounded-3 bg-light">
            <div class="p-3 d-flex align-items-center f-14 bold">
              <b-icon icon="journal-check" class="text-primary mr-2" />
              <i class="bi bi-journal-check"></i> Histori Presensi
            </div>
            <div class="bd-card-content p-2">
              <div
                class="d-flex justify-content-center align-items-center flex-column p-5"
                v-if="form.loading"
              >
                <b-spinner
                  class="f-12"
                  type="grow"
                  label="Spinning"
                  style="background-color: #159895"
                ></b-spinner>
                <span class="f-11 mt-1 bold">Loading....</span>
              </div>

              <div v-else>
                <div class="d-flex flex-column" v-if="absensi.length > 0">
                  <div
                    class="bd-card-item p-2 ml-1 mr-1 text-white mb-1"
                    :class="absensi.bg"
                    style="border-radius: 5px"
                    v-for="(absensi, absensiidx) in absensi"
                    :key="`absensi-${absensiidx}`"
                  >
                    <div
                      v-if="absensi.masuk || absensi.keluar"
                      class="d-flex justify-content-between"
                    >
                      <div>
                        <div v-if="absensi.masuk">
                          <h6 class="m-0 p-0">{{ absensi.masuk }}</h6>
                          <label class="m-0 p-0 f-12">{{
                            dateFormat(absensi.tgl_masuk)
                          }}</label>
                        </div>
                        <div v-else>
                          <span class="f-12">{{ absensi.keterangan }}</span>
                        </div>
                      </div>
                      <div style="text-align: end">
                        <div v-if="absensi.keluar">
                          <h6 class="m-0 p-0">{{ absensi.keluar }}</h6>
                          <label class="m-0 p-0 f-12">{{
                            dateFormat(absensi.tgl_keluar)
                          }}</label>
                        </div>
                        <div v-else class="mt-2">
                          <span class="f-12">{{ absensi.keterangan }}</span>
                        </div>
                      </div>
                    </div>
                    <div v-else class="d-flex justify-content-between mt-2">
                      <span class="f-12">{{ absensi.keterangan }}</span>
                    </div>
                  </div>
                </div>
                <div class="d-flex" v-else>
                  <div
                    class="bd-card-item p-2 text-white d-flex justify-content-center bg-card-cus-content-list w-100"
                  >
                    <span class="f-12 bold">Data presensi tidak ditemukan</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<style lang="scss">
.bg-card-cus-content-list {
  border: none;
  background-color: #159895;
}
.bg-card-cus-pending-list {
  border: none;
  background-color: #f58840;
}

.bg-card-grey {
  border: none;
  color: black;
  background-color: #b2b2b2;
}
.bg-card-cus {
  background-color: #ecf9ff;
}
button.submit {
  font-size: 11px;
  text-transform: uppercase;
  background-color: #159895;
  border: none;
}
button.cancel {
  font-size: 11px;
  text-transform: uppercase;
  background-color: #dc3535;
  border: none;
}
.button-sag {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: rgb(119, 3, 123);
  border: none;
}
.button-sag:hover {
  background-color: rgb(119, 3, 123);
}
.waktu {
  font-size: 4em;
  color: white;
  text-align: center;
  margin-bottom: 0;
}

.tanggal {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
}
.f-12 {
  font-size: 12px;
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}

.bg-titles {
  text-transform: uppercase;
  width: 100%;
  color: white;
  overflow: auto;
  background: linear-gradient(
    320deg,
    rgb(119, 3, 123) 3%,
    rgb(147, 54, 180) 38%,
    rgb(221, 88, 214) 68%,
    rgb(64, 18, 139) 98%
  );
  animation: gradient 15s ease infinite;
  background-size: 300% 300%;
  background-attachment: fixed;
  @keyframes gradient {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
}
</style>
<style scoped>
.waktu {
  font-size: 4em;
  color: white;
  text-align: center;
  margin-bottom: 0;
}

.tanggal {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
}
</style>
<script>
import { mapGetters } from "vuex";
import axios from "@/axios";
export default {
  data() {
    return {
      last: {
        tap_masuk: null,
        tap_keluar: null,
        tgl_masuk: null,
        tgl_keluar: null,
        shift: null,
      },
      absensi: [],
      time: "",
      date: "",
      form: {
        data: {
          nik: null,
          bulan: new Date().getMonth() + 1,
          tahun: new Date().getFullYear(),
        },
        loading: false,
      },
      opt: {
        bulan: [
          {
            value: "00",
            text: "Pilih Bulan",
          },
          {
            value: "01",
            text: "Januari",
          },
          {
            value: "02",
            text: "Februari",
          },
          {
            value: "03",
            text: "Maret",
          },
          {
            value: "04",
            text: "April",
          },
          {
            value: "05",
            text: "Mei",
          },
          {
            value: "06",
            text: "Juni",
          },
          {
            value: "07",
            text: "Juli",
          },
          {
            value: "08",
            text: "Agustus",
          },
          {
            value: "09",
            text: "September",
          },
          {
            value: "10",
            text: "Oktober",
          },
          {
            value: "11",
            text: "November",
          },
          {
            value: "12",
            text: "Desember",
          },
        ],
        tahun: [],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    formattedDate(tanggalISO) {
      const hariIndonesia = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      const tanggal = new Date(tanggalISO);
      const hari = hariIndonesia[tanggal.getDay()];
      const tgl = String(tanggal.getDate()).padStart(2, "0");
      const bulan = String(tanggal.getMonth() + 1).padStart(2, "0");
      const tahun = tanggal.getFullYear();
      return `${hari}, ${tgl}-${bulan}-${tahun}`;
    },
    hasHistory() {
      return window.history.length > 2;
    },
    setUpForm() {
      let userData = this.user.data;
      this.form.data.nik = userData.nik;
    },
    // UNUTK MENAMPILKAN JAM
    updateTime() {
      const now = new Date();
      const hours = this.formatTime(now.getHours());
      const minutes = this.formatTime(now.getMinutes());
      const year = now.getFullYear();
      const month = this.formatTime(now.getMonth() + 1);
      const day = this.formatTime(now.getDate());
      const dayOfWeek = this.getDayOfWeek(now.getDay());
      this.time = `${hours}:${minutes}`;
      this.date = `${dayOfWeek}, ${day}-${month}-${year}`;
    },
    formatTime(time) {
      return time < 10 ? `0${time}` : time;
    },
    getDayOfWeek(day) {
      const daysOfWeek = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      return daysOfWeek[day];
    },
    // END MENAMPILKAN JAM
    async getHistoriAbsensi() {
      this.form.loading = true;
      let url = "/presenceSecurity/presence_detail";
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let payload = new FormData();
      for (let key in this.form.data) {
        payload.append(key, this.form.data[key]);
      }
      payload.append(
        "periode",
        `${this.form.data.bulan}-${this.form.data.tahun}`
      );
      payload.append("nik", this.user.data.nik);
      let req = await axios.post(url, payload, header);
      if (req.data.status) {
        let absensi = [];
        req.data.msg.map((x) => {
          let bg =
            x.keterangan == "Hadir"
              ? "bg-card-cus-content-list"
              : "bg-card-cus-pending-list";
          x.bg = bg;
          absensi.push(x);
        });
        this.absensi = absensi;
        this.form.loading = false;
      } else {
        this.form.loading = false;
      }
    },
    async doGetData() {
      this.form.loading = false;
      let payload = new FormData();
      for (let key in this.form.data) {
        payload.append(key, this.form.data[key]);
      }
      payload.append("nik", this.user.data.nik);
      // let url = "http://localhost/simpres/api/Absensi/data_absen_new";
      let url = "https://simpres.baytulikhtiar.com/api/Absensi/data_absen_new";
      try {
        let req = await axios.post(url, payload);
        if (req.data.status) {
          this.last.tap_masuk = req.data.msg.tap_masuk;
          this.last.tap_keluar = req.data.msg.tap_keluar;
          this.last.tgl_masuk = req.data.msg.tgl_masuk;
          this.last.tgl_keluar = req.data.msg.tgl_keluar;
          this.last.shift = req.data.msg.shift;
          this.form.loading = false;
        } else {
          this.form.loading = false;
        }
      } catch (error) {
        this.form.loading = false;
        console.error(error);
      }
    },
    notif(msg, title, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-full",
      });
    },
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
    dateFormat(date) {
      let theDate = new Date(date);
      let days = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      let day = theDate.getDay();
      let d = theDate.getDate();
      let m = theDate.getMonth() + 1;
      let y = theDate.getFullYear();
      return `${days[day]}, ${d}-${m}-${y}`;
    },
  },
  mounted() {
    this.setUpForm();
    this.doGetData();
    setInterval(() => {
      this.updateTime();
    }, 1000);
    this.getHistoriAbsensi();
    this.form.data.bulan = this.pad(this.form.data.bulan, 2);
  },
  created() {
    let thisYear = new Date().getFullYear() + 1;
    for (let x = thisYear; x > 1990; x--) {
      let tahun = {
        value: x,
        text: x,
      };
      this.opt.tahun.push(tahun);
    }
  },
};
</script>