var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-3",attrs:{"id":"app"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.doSubmit.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"mb-3 f-13 bold",attrs:{"label":"kode_cabang","hidden":""}},[_c('b-input',{staticClass:"f-13",model:{value:(_vm.form.data.branch_code),callback:function ($$v) {_vm.$set(_vm.form.data, "branch_code", $$v)},expression:"form.data.branch_code"}})],1),_c('b-form-group',{staticClass:"mb-3 f-13 bold",attrs:{"label":"fullname","hidden":""}},[_c('b-input',{staticClass:"f-13",model:{value:(_vm.form.data.fullname),callback:function ($$v) {_vm.$set(_vm.form.data, "fullname", $$v)},expression:"form.data.fullname"}})],1),_c('b-form-group',{staticClass:"mb-3 f-13 bold",attrs:{"label":"latitude","hidden":""}},[_c('b-input',{staticClass:"f-13",model:{value:(_vm.form.data.latitude),callback:function ($$v) {_vm.$set(_vm.form.data, "latitude", $$v)},expression:"form.data.latitude"}})],1),_c('b-form-group',{staticClass:"mb-3 f-13 bold",attrs:{"label":"longitude","hidden":""}},[_c('b-input',{staticClass:"f-13",model:{value:(_vm.form.data.longitude),callback:function ($$v) {_vm.$set(_vm.form.data, "longitude", $$v)},expression:"form.data.longitude"}})],1),_c('div',{staticClass:"p-3 text-center"},[_c('div',[_c('h6',{staticClass:"text-center",staticStyle:{"font-size":"13px"}},[_vm._v(" Koordinat Lokasi Kantor Cabang "),_c('br')]),_c('h6',{staticStyle:{"text-transform":"uppercase","font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.form.data.branch_name)+" ")]),_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Latitude: "+_vm._s(_vm.form.data.latitude)+" | Longitude: "+_vm._s(_vm.form.data.longitude)+" ")])]),_c('div',{staticClass:"map-example mt-3"},[_c('bing-map',{attrs:{"credentials":"As6nrYCa3lv21vs78OyFrPSAeQ0l7J__Z8o9PHTFGcCKIQkXodw0LZdOl4znFu4I","options":_vm.mapOptions,"center":{
            latitude: _vm.form.data.latitude,
            longitude: _vm.form.data.longitude,
          }}},[_c('bing-map-layer',[_c('bing-map-pushpin',{attrs:{"location":{
                latitude: _vm.form.data.latitude,
                longitude: _vm.form.data.longitude,
              },"options":{
                icon: 'https://bingmapsisdk.blob.core.windows.net/isdksamples/defaultPushpin.png',
              }}})],1)],1)],1)]),_c('b-col',{staticClass:"justify-content-center d-flex",staticStyle:{"color":"red"},attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"10px"}},[_c('i',[_vm._v("*Silahkan registrasi lokasi kantor cabang, pastikan titik lokasi di "),_c('b',[_vm._v("map")]),_vm._v(" dan posisi anda sudah "),_c('b',[_vm._v("sesuai")]),_vm._v(". "),_c('br'),_vm._v(" Jika ada error silahkan hubungi team IT Support.")])])]),_c('b-col',{staticClass:"justify-content-center d-flex pb-5",attrs:{"cols":"12"}},[_c('b-col',{staticClass:"p-3",attrs:{"cols":"8"}},[_c('b-button',{staticClass:"w-100 button-sag",attrs:{"block":"","type":"submit","disabled":_vm.form.loading}},[_vm._v(_vm._s(_vm.form.loading ? "Memproses..." : "Regis Lokasi Kantor"))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }