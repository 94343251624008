<template>
  <div id="app" class="pb-3">
    <div class="baik-title w-100 bg-title">
      <b-row class="g-0 d-flex align-items-center" no-gutters>
        <div class="bt-left d-flex p-3">
          <button
            type="button"
            @click="hasHistory() ? $router.go(-1) : $router.push('/')"
            class="m-0 p-0 btn btn-outline-success"
          >
            <b-icon icon="arrow-left-circle-fill" />
          </button>
        </div>
        <div>
          <h6 class="m-0 p-0 text-light f-14">Form Absensi Security</h6>
        </div>
      </b-row>
    </div>
    <div class="security">
      <div class="background pb-5">
        <div class="p-3">
          <div class="mt-3 pb-2">
            <h1 class="waktu">{{ time }}</h1>
            <p class="tanggal">{{ date }}</p>
            <!-- <p class="koordinat">
              {{ form.data.latitude }} | {{ form.data.longitude }}
            </p> -->
          </div>
          <div class="mt-3" style="font-size: 12px">
            <!-- <p>
              Anda harus berada dalam radius 10 meter untuk dapat mengisi form
              absen.
            </p> -->
          </div>
          <div style="margin-top: -20px; font-size: 12px">
            <!-- <p
              v-if="
                form.kantor_cabang.latitude != 0 &&
                form.kantor_cabang.longitude != 0
              "
            >
              Jarak Ke Kantor Cabang {{ form.kantor_cabang.nama_cabang }}:
              <b>{{ jarak }}</b>
            </p>
            <p v-else>Silahkan pilih terlebih dahulu kantor tujuan</p> -->
            <p>Silahkan pilih terlebih dahulu kantor tujuan</p>
          </div>
        </div>
      </div>
      <div class="backgroundd">
        <div class="p-3">
          <div class="kantor mb-4">
            <b-form-group label="Kantor Cabang" class="f-12 bold">
              <b-form-select
                required
                class="f-12 px-3 pt-2"
                placeholder="Pilih Kantor Cabang"
                v-model="form.kantor_cabang"
                :options="opt.kantor_cabang"
                @change="updateBranchLocation"
              />
            </b-form-group>
          </div>
          <!-- <div v-if="!isWithinRadius" class="map-example pb-5"> -->
          <!-- <div class="map-example pb-5">
            <bing-map
              credentials="As6nrYCa3lv21vs78OyFrPSAeQ0l7J__Z8o9PHTFGcCKIQkXodw0LZdOl4znFu4I"
              :options="mapOptions"
            >
              <bing-map-layer>
                <bing-map-pushpin
                  :location="{
                    latitude: form.data.latitude,
                    longitude: form.data.longitude,
                  }"
                  :options="{
                    icon: 'https://bingmapsisdk.blob.core.windows.net/isdksamples/defaultPushpin.png',
                  }"
                >
                </bing-map-pushpin>
                <bing-map-pushpin
                  :location="{
                    latitude: form.kantor_cabang.latitude,
                    longitude: form.kantor_cabang.longitude,
                  }"
                  :options="{
                    icon: 'https://bingmapsisdk.blob.core.windows.net/isdksamples/defaultPushpin.png',
                  }"
                >
                </bing-map-pushpin>
              </bing-map-layer>
            </bing-map>
          </div> -->
          <div class="pb-5">
            <h5 class="bold" style="text-transform: uppercase">
              HI, {{ user.data.fullname }} <br />SELAMAT DATANG
            </h5>
            <h6 class="f-12 bold mb-3">
              Anda Sudah Masuk Ke Radius Absen Di Kantor
              {{ form.kantor_cabang.nama_cabang }}
            </h6>
            <div>
              <div class="web-camera-container">
                <div class="camera-button">
                  <button type="button" class="button" @click="toggleCamera">
                    <span v-if="!isCameraOpen">
                      <b-icon class="icon" :icon="'camera-fill'" /> Open
                      Camera</span
                    >
                    <span v-else>
                      <b-icon class="icon" :icon="'camera-video-off-fill'" />
                      Close Camera</span
                    >
                  </button>
                </div>
                <div v-show="isCameraOpen && isLoading" class="camera-loading">
                  <ul class="loader-circle pt-3">
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
                <div
                  v-if="isCameraOpen"
                  v-show="!isLoading"
                  class="camera-box"
                  :class="{ flash: isShotPhoto }"
                >
                  <!-- <div
                    class="camera-shutter"
                    :class="{ flash: isShotPhoto }"
                  ></div> -->
                  <video v-show="!isPhotoTaken" ref="camera" autoplay></video>
                  <canvas
                    class="camera"
                    v-show="isPhotoTaken"
                    id="photoTaken"
                    ref="canvas"
                  ></canvas>
                  <div class="camera-shoot">
                    <button type="button" class="button" @click="takePhoto">
                      <b-icon
                        class="icon"
                        :icon="
                          form.data.selfi != null
                            ? 'arrow-repeat'
                            : 'camera-fill'
                        "
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <b-form-group label="Kategori Shift" class="f-12 bold">
                <b-form-select
                  class="f-12 px-3 pt-2"
                  placeholder="Pilih Kategori Shift"
                  v-model="form.data.kategori_shift"
                  :options="opt.kategori_shift"
                />
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="Kategori Absen"
                class="f-12 bold"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  class="w-100 pt-2 px-3"
                  v-model="form.data.kategori_absen"
                  :options="opt.kategori_absen"
                  :aria-describedby="ariaDescribedby"
                  :button-variant="buttonVariant"
                  name="kategori-absen"
                  buttons
                  size="sm"
                >
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div>
              <b-col cols="12 pb-5">
                <b-button
                  class="button-sag w-100"
                  @click="$bvModal.show('modal-form')"
                  :disabled="isFormIncomplete"
                  >Absen</b-button
                >
              </b-col>
            </div>
          </div>
        </div>
        <div>
          <b-modal
            title="Form Absen"
            id="modal-form"
            hide-header-close
            hide-footer
            centered
          >
            <b-form @submit.prevent="doSubmit">
              <b-row>
                <b-input
                  class="f-13"
                  v-model="form.data.latitude"
                  :required="!form.data.latitude"
                  hidden
                />
                <b-input
                  class="f-13"
                  v-model="form.data.longitude"
                  :required="!form.data.longitude"
                  hidden
                />
                <b-col cols="12">
                  <b-form-group class="f-12 bold" label="Foto Selfi">
                    <label for="fm-foto" class="w-100" ref="previewImage">
                      <b-img-lazy
                        :src="
                          form.data.selfi
                            ? form.data.selfi
                            : 'https://www.seekpng.com/png/detail/110-1100707_person-avatar-placeholder.png'
                        "
                        fluid
                        class="w-100 selfi"
                      />
                    </label>
                  </b-form-group>
                  <p
                    v-if="!form.data.selfi"
                    style="font-size: 10px; color: red"
                  >
                    *Mohon Maaf, Bapak <b>{{ user.data.fullname }}</b> belum
                    melakukan foto, silahkan foto terlebih dahulu
                  </p>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Nama Lengkap"
                    class="mb-3 f-13 bold"
                    disabled
                  >
                    <b-input
                      class="f-13"
                      v-model="form.data.fullname"
                      :required="!form.data.fullname"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Tugas Di Kantor"
                    class="mb-3 f-13 bold"
                    disabled
                  >
                    <b-input
                      class="f-13"
                      v-model="form.kantor_cabang.nama_cabang"
                      :required="!form.kantor_cabang"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Kategoti Shift"
                    class="mb-3 f-13 bold"
                    disabled
                  >
                    <b-input
                      class="f-13"
                      v-model="form.data.kategori_shift"
                      :required="!form.data.kategori_shift"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Kategori Absen"
                    class="mb-3 f-13 bold"
                    disabled
                  >
                    <b-input
                      class="f-13"
                      v-model="form.data.kategori_absen"
                      :required="!form.data.kantor_absen"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  class="d-flex justify-content-end border-top pt-3 px-3"
                >
                  <b-button
                    size="sm px-3"
                    class="cancel"
                    variant="secondary"
                    @click="$bvModal.hide('modal-form')"
                    :disabled="form.loading"
                    >Cancel
                  </b-button>
                  <b-button
                    size="sm"
                    variant="primary"
                    type="submit"
                    :disabled="form.loading"
                    class="ml-3 px-3 submit"
                  >
                    {{ form.loading ? "Memproses..." : "Kirim" }}
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
button.submit {
  font-size: 11px;
  text-transform: uppercase;
  background-color: #159895;
  border: none;
}
button.cancel {
  font-size: 11px;
  text-transform: uppercase;
  background-color: #dc3535;
  border: none;
}
.map-example {
  margin-top: 10px;
  width: 100%;
  height: 400px;
}
.button-sag {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: rgb(119, 3, 123);
  border: none;
}
.button-sag:hover {
  background-color: rgb(119, 3, 123);
}
.waktu {
  font-size: 4em;
  color: white;
  text-align: center;
  margin-bottom: 0;
}

.tanggal {
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
  margin-top: 0;
}
.address {
  font-size: 1em;
  text-align: center;
  margin-top: -16px;
}
.koordinat {
  font-size: 12px;
  text-align: center;
  margin-top: -16px;
}
.f-12 {
  font-size: 12px;
}
.f-13 {
  font-size: 13px;
}
.f-14 {
  font-size: 14px;
}

.bg-title {
  text-transform: uppercase;
  width: 100%;
  color: white;
  overflow: auto;
  background: linear-gradient(
    320deg,
    rgb(119, 3, 123) 3%,
    rgb(147, 54, 180) 38%,
    rgb(221, 88, 214) 68%,
    rgb(64, 18, 139) 98%
  );
  animation: gradient 15s ease infinite;
  background-size: 300% 300%;
  background-attachment: fixed;
  @keyframes gradient {
    0% {
      background-position: 0% 0%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
}
</style>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
export default {
  props: {
    msg: String,
    title: String,
  },
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: "#",
      form: {
        data: {
          selfi: null,
          kategori_shift: null,
          kategori_absen: null,
          nik: null,
          fullname: null,
          latitude: 0,
          longitude: 0,
          kantor_cabang: null,
        },
        loading: false,
        kantor_cabang: {
          kd_cabang: null,
          nama_cabang: null,
          latitude: 0,
          longitude: 0,
        },
      },
      opt: {
        kantor_cabang: [],
        kategori_shift: [],
        kategori_absen: [
          {
            value: "masuk",
            text: "MASUK",
          },
          {
            value: "keluar",
            text: "KELUAR",
          },
        ],
      },
      distance: 0.0,
      jarak: 0.0,
      mapOptions: {
        zoom: 18,
        showLocateMeButton: true,
        supportedMapTypes: [
          "aerial",
          "canvasDark",
          "canvasLight",
          "birdseye",
          "ordnanceSurvey",
          "road",
        ],
        showTrafficButton: true,
        disableBirdseye: true,
      },
      time: "",
      date: "",
      gradient: {
        radial: false,
        colors: [
          { color: "#8B1874", offset: "0", opacity: "1" },
          { color: "#BA90C6", offset: "100", opacity: "0.6" },
        ],
      },
    };
  },
  created() {
    if (navigator.geolocation) {
      // Mendapatkan lokasi pengguna
      navigator.geolocation.watchPosition(
        async (position) => {
          // Mendapatkan koordinat latitude dan longitude
          this.form.data.latitude = position.coords.latitude;
          this.form.data.longitude = position.coords.longitude;

          // Menghitung jarak antara lokasi pengguna dan lokasi kantor cabang
          if (this.form.data.latitude === 0 && this.form.data.longitude === 0) {
            // Menampilkan notifikasi jika latitude dan longitude = 0
            this.notif(
              "Maaf, titik posisi belum didapatkan. Silakan aktifkan GPS dan refresh kembali.",
              "Pemberitahuan",
              "danger"
            );
          } else {
            this.distance = this.hitungDistance(
              this.form.data.latitude,
              this.form.data.longitude,
              this.form.kantor_cabang.latitude,
              this.form.kantor_cabang.longitude
            );
            this.jarak = this.calculateDistance(
              this.form.data.latitude,
              this.form.data.longitude,
              this.form.kantor_cabang.latitude,
              this.form.kantor_cabang.longitude
            );
          }
        },
        (error) => {
          console.error(error.message);
          // Menampilkan notifikasi jika gagal mendapatkan lokasi
          this.notif(
            "Maaf, Silahkan Aktifkan GPS Terlebih dahulu",
            "Pemberitahuan",
            "danger"
          );
        }
      );
    } else {
      // Menampilkan notifikasi jika browser tidak mendukung geolocation
      this.notif(
        "Maaf, Browser Anda Tidak Mendukung",
        "Pemberitahuan",
        "danger"
      );
    }
  },
  computed: {
    isWithinRadius() {
      const radius = 10.0; // Misalnya, radius yang diinginkan adalah 1000 meter
      return this.distance != 0 && this.distance <= radius;
    },
    buttonVariant() {
      if (this.form.data.kategori_absen == "masuk") {
        return "outline-success";
      } else if (this.form.data.kategori_absen == "keluar") {
        return "outline-warning";
      } else {
        return "outline-secondary";
      }
    },
    isFormIncomplete() {
      const formData = this.form.data;

      for (const key in formData) {
        if (Object.prototype.hasOwnProperty.call(formData, key)) {
          const value = formData[key];

          if (
            value === null ||
            (typeof value === "object" && Object.values(value).includes(null))
          ) {
            return true; // Disable the button
          }
        }
      }
      return false; // Enable the button
    },
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["logout"]),
    // UNTUK KEMBALI KE HALAMAN SEBELUMNYA
    hasHistory() {
      if (this.form.data.isCameraOpen == true) {
        this.stopCameraStream();
        return window.history.length > 2;
      } else {
        return window.history.length > 2;
      }
    },
    // UNUTK MENAMPILKAN JAM
    updateTime() {
      const now = new Date();
      const hours = this.formatTime(now.getHours());
      const minutes = this.formatTime(now.getMinutes());
      const year = now.getFullYear();
      const month = this.formatTime(now.getMonth() + 1);
      const day = this.formatTime(now.getDate());
      const dayOfWeek = this.getDayOfWeek(now.getDay());
      this.time = `${hours}:${minutes}`;
      this.date = `${dayOfWeek}, ${day}-${month}-${year}`;
    },
    formatTime(time) {
      return time < 10 ? `0${time}` : time;
    },
    getDayOfWeek(day) {
      const daysOfWeek = [
        "Minggu",
        "Senin",
        "Selasa",
        "Rabu",
        "Kamis",
        "Jumat",
        "Sabtu",
      ];
      return daysOfWeek[day];
    },
    // END MENAMPILKAN JAM

    setUpForm() {
      let userData = this.user.data;
      this.form.data.nik = userData.nik;
      this.form.data.fullname = userData.fullname;
      this.form.data.branch_code = userData.branch_code;
      this.form.data.branch_name = userData.branch_name;
    },

    // MENGHITUNG JARAK DARI KANTOR CABANG YANG DIPILIH
    updateBranchLocation() {
      // Retrieve the selected option
      const selectedOption = this.form.kantor_cabang;

      // Update latitude and longitude based on the selected option
      this.form.data.kantor_cabang = selectedOption.kd_cabang;
      this.form.kantor_cabang.latitude = selectedOption.latitude;
      this.form.kantor_cabang.longitude = selectedOption.longitude;
      // Recalculate distance using the new latitude and longitude values
      this.distance = this.hitungDistance(
        this.form.data.latitude,
        this.form.data.longitude,
        this.form.kantor_cabang.latitude,
        this.form.kantor_cabang.longitude
      );
      this.jarak = this.calculateDistance(
        this.form.data.latitude,
        this.form.data.longitude,
        this.form.kantor_cabang.latitude,
        this.form.kantor_cabang.longitude
      );
    },
    // END MENGHITUNG JARAK DARI KANTOR CABANG

    // MENGHITUNG RADIUS DALAM METER
    hitungDistance(lat1, lon1, lat2, lon2) {
      const earthRadius = 6371000; // Radius bumi dalam meter
      const toRad = (value) => (value * Math.PI) / 180; // Konversi sudut menjadi radian
      const deltaLat = toRad(lat2 - lat1);
      const deltaLon = toRad(lon2 - lon1);
      const a =
        Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(toRad(lat1)) *
          Math.cos(toRad(lat2)) *
          Math.sin(deltaLon / 2) *
          Math.sin(deltaLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = earthRadius * c; // Jarak dalam meter
      return distance.toFixed(1); // Tampilkan jarak dalam meter dengan 2 angka desimal
    },
    // END MENGHITUNG RADIUS DALAM METER
    // MENAMPILKAN HITUNGAN RADIUS DALAM METER
    calculateDistance(lat1, lon1, lat2, lon2) {
      const earthRadius = 6371000; // Radius bumi dalam meter
      const toRad = (value) => (value * Math.PI) / 180; // Konversi sudut menjadi radian
      const deltaLat = toRad(lat2 - lat1);
      const deltaLon = toRad(lon2 - lon1);
      const a =
        Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(toRad(lat1)) *
          Math.cos(toRad(lat2)) *
          Math.sin(deltaLon / 2) *
          Math.sin(deltaLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const jarak = earthRadius * c; // Jarak dalam meter

      if (jarak < 1000) {
        return jarak.toFixed(1) + " Meter"; // Tampilkan jarak dalam meter dengan 2 angka desimal
      } else {
        return (jarak / 1000).toFixed(1) + " KM"; // Tampilkan jarak dalam meter dengan 2 angka desimal
      }
    },
    // END MENGHITUNF RADIUS DALAM METER
    // METHOD MENGAMBIL DATA DARI API
    async getKategoriShift() {
      let url = "/PresenceSecurity/list_kategori_shift";
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let req = await axios.get(url, header);
      let data = req.data.msg;
      this.opt.kategori_shift = [
        {
          value: null,
          text: "Pilih Kategori Shift",
          disabled: true,
        },
      ];
      // PROSES LOOP DAN PUSH KE OPT KANTOR CABANG
      data.map((item) => {
        this.opt.kategori_shift.push({
          value: item.shift,
          text:
            "SHIFT" +
            " " +
            item.shift +
            " " +
            "(" +
            item.jam_masuk +
            "-" +
            item.jam_keluar +
            ")",
        });
      });
    },
    async getCabang() {
      let url = "/PresenceSecurity/list_koordinasi_cabang";
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let req = await axios.get(url, header);
      let data = req.data.msg;
      this.opt.kantor_cabang = [
        {
          value: {
            kd_cabang: null,
            nama_cabang: null,
            latitude: 0,
            longitude: 0,
          },
          text: "Pilih Cabang Tujuan",
          disabled: true,
        },
      ];
      // PROSES LOOP DAN PUSH KE OPT KANTOR CABANG
      data.map((item) => {
        this.opt.kantor_cabang.push({
          value: {
            kd_cabang: item.kd_cabang,
            nama_cabang: item.nama_cabang,
            latitude: parseFloat(item.latitude),
            longitude: parseFloat(item.longitude),
          },
          text: item.nama_cabang,
        });
      });
    },
    // CAMER SELFIR
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },

    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },

    previewImage(event) {
      let theImg = null;
      let vm = this;
      const foto = this.$refs["fm-foto"];
      let reader = new FileReader();
      theImg = event.target.files[0];
      reader.readAsDataURL(theImg);
      reader.onload = function () {
        vm.form.data.selfi = reader.result;
        foto.type = "text";
        foto.type = "file";
      };
      reader.onerror = function () {
        vm.form.data.selfi = null;
        foto.type = "text";
        foto.type = "file";
      };
    },
    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext("2d");
      const video = this.$refs.camera;

      // Set ukuran canvas secara eksplisit
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // Gambar video ke dalam canvas dengan ukuran yang ditetapkan
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      if (!this.form.data.selfi) {
        this.form.data.selfi = canvas.toDataURL();
      } else {
        this.form.data.selfi = null;
      }
    },
    // END CAMERA SELFIE
    // KIRIM DATA KE DATABASE
    async doSubmit() {
      this.form.loading = true;
      let url = "https://simpres.baytulikhtiar.com/api/Absensi/absen_security";
      // let url = "http://localhost/simpres/api/Absensi/absen_security";
      let payload = new FormData();
      for (let key in this.form.data) {
        payload.append(key, this.form.data[key]);
      }
      let req = await axios.post(url, payload);
      if (req.status) {
        this.form.loading = false;
        this.$swal({
          toast: true,
          position: "top-center",
          showConfirmButton: false,
          timer: 2000,

          icon: "success",
          title: "Hi," + " " + this.form.data.fullname,
          text: "Anda Berhasil Absen" + " " + this.form.data.kategori_absen,
        }).then(() => {
          this.clearForm();
          this.stopCameraStream();
          setTimeout(() => {
            this.$router.push("/absen-security");
          }, 2000);
        });
      } else {
        this.$swal({
          toast: true,
          position: "top-center",
          showConfirmButton: false,
          timer: 3000,

          icon: "error",
          title: "Hi," + " " + this.form.data.fullname,
          text: "Anda Gagal Absen" + " " + this.form.data.kategori_absen,
        });
      }
    },
    // END KIRIM DATA KE DATABASE
    clearForm() {
      this.form.data.selfi = null;
      this.form.data.kategori_shift = null;
      this.form.data.kategori_absen = null;
      this.form.data.nik = null;
      this.form.data.fullname = null;
      this.form.data.latitude = 0;
      this.form.data.longitude = 0;
      this.form.data.kantor_cabang = null;
    },
    // NOTIFIKASI
    notif(msg, title, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-full",
      });
    },
    // END NOTIFIKASI
  },
  mounted() {
    this.setUpForm();
    this.getCabang();
    this.getKategoriShift();
    this.distance = this.hitungDistance(
      this.form.data.latitude,
      this.form.data.longitude,
      this.form.kantor_cabang.latitude,
      this.form.kantor_cabang.longitude
    );
    setInterval(() => {
      this.updateTime();
    }, 1000);
  },
};
</script>
<style lang="scss">
video {
  width: 100%;
  height: auto;
  object-fit: cover; /* Menutupi area canvas dengan mempertahankan aspek rasio */
  object-position: center; /* Mengatur posisi gambar di tengah */
  transform: scaleX(-1); /* Memutar gambar secara horizontal */
}
canvas.camera {
  width: 100%;
  height: auto;
  object-fit: cover; /* Menutupi area canvas dengan mempertahankan aspek rasio */
  object-position: center; /* Mengatur posisi gambar di tengah */
  transform: scaleX(-1); /* Memutar gambar secara horizontal */
}
.selfi {
  width: 100%;
  height: auto;
  object-fit: cover; /* Menutupi area canvas dengan mempertahankan aspek rasio */
  object-position: center; /* Mengatur posisi gambar di tengah */
  transform: scaleX(-1); /* Memutar gambar secara horizontal */
}
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(119, 3, 123);
  border-radius: 4px;
  width: 100%;
  background-color: #f8f9fa !important;

  .camera-button {
    margin: 2rem;
    font-size: 12px;
    button {
      padding: 5px 20px 5px 20px;
      border-radius: 10px;
      background-color: rgb(119, 3, 123);
      border: none;
      color: white;
      .icon {
        font-size: 14px;
      }
    }
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 100%;
      height: 300px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    display: flex;
    margin: 1rem 0;
    width: 100%;
    justify-content: center;
    button {
      display: flex;
      padding: 10px;
      background-color: rgb(119, 3, 123);
      border: none;
      color: white;
      border-radius: 100%;
      .icon {
        font-size: 30px;
      }
    }
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -3rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background-color: rgb(119, 3, 123);
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.4;
    }

    100% {
      opacity: 1;
    }
  }
}
</style>
<!-- note : tgl 07-05-2024 -->
<!-- pendeteksian lokasi dihilangkan karena security saat ini tidak support HP nya kepada teknologi yang dibuat -->